body {
  background-color: #121214;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: white;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 0px; /* Adjust based on your bottom bar height */
  box-sizing: border-box;
}

.top-bar {
  display: flex;
  justify-content: right;
  padding: 10px;
  padding-bottom: 16px;
  padding-top: 16px;
  background-color: #1e1e1e;
  border-bottom: 0px solid #4c4c4c;
  width: 100vw;
  box-sizing: border-box;
  position:fixed;
  top: 0;
  left: 0;
  /* z-index: 1000; */
}

.icon-button {
  display: flex;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  padding: 2px;
  font-size: 28px;
  margin-right: 28px;
}


.icon-button:hover {
  color: white;
  background: none;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #2c2c2c;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  margin-top: 80px;
  margin-bottom: 10px;
}

h1 {
  text-align: center;
  color: #FFFFFF;
  font-size: 3em;
  margin-bottom: 30px;
}

form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #444;
  background-color: #3c3c3c;
  color: white;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0;
}

.input-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
}

.suggestions-list {
  background-color: #1e1e1e;
  border: 1px solid #555;
  border-top: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #4c4c4c;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
}



button:hover {
  background-color: #45a049;
}

.content-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
}

.content-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3c3c3c;
  padding: 15px;
  border-radius: 8px;
}


.brand-hint, .svg-view {
  width: 100%;
  height: 150px; /* Adjust this value as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}



.brand-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
}

.brand-hint img {
  max-width: 200px;
  max-height: 200px;
  object-fit: contain;
}

.brand-hint span {
  font-size: 18px;
  display: none;
}

.svg-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
}

.svg-view img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 4px;
}

.guesses-container {
  width: 100%;
  margin-top: 20px;
}

.guess-row {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 5px;
  margin-bottom: 15px;
  background-color: #3c3c3c;
  padding: 10px;
  border-radius: 4px;
}

.guess-row.header {
  position: sticky;
  top: 60px; /* Adjust this value to match your top bar height */
  z-index: 10;
  background-color: #4c4c4c;
  margin-bottom: 20px;
}


.guess-row.header .guess-item {
  font-size: 24px;
}

.guess-item {
  text-align: center;
  padding: 8px 5px;
  border: 1px solid #555;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 80px;
  word-break: break-word;
  font-size: 18px;
}
.correct {
  background-color: #4caf50;
}

.close {
  background-color: #ffeb3b;
  color: black;
}

.game-over {
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  background-color: #3c3c3c;
  border-radius: 4px;
  width: 100%;
  max-width: 600px;
}

.dropdown-content {
  position: fixed;
  top: 60px;
  right: 10px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dropdown-header h2 {
  margin: 0;
  font-size: 24px;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.close-button:hover {
  color: white;
  background: none;
}
.help-content ol {
  padding-left: 20px;
}

.help-content li {
  margin-bottom: 10px;
}


.help-content .yellow {
  color: #ffeb3b;
}

.help-content .green {
  color: #4caf50;
}

.about-content p {
  margin-bottom: 10px;
}

.about-content a {
  color: #4caf50;
  text-decoration: none;
}

.about-content a:hover {
  text-decoration: underline;
}

.social-links {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.version {
  font-size: 12px;
  color: #888;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .game-container {
    padding: 10px;
  }

  .guess-row {
    font-size: 12px;
  }

  .guess-item {
    padding: 5px 2px;
    font-size: 12px;
    min-width: 60px;
  }

  .guess-row.header {
    position: sticky;
    top: 60px; /* Adjust this value to match your top bar height */
    z-index: 10;
    background-color: #4c4c4c;
    margin-bottom: 20px;
  }
}